const isLocal = document.location.href.indexOf("localhost") > -1
const isDev = document.location.host === 'gmdev.warforgalaxy.com'

const isProd = !isLocal && !isDev

export default {
    ccBaseURL: 'https://cc.warforgalaxy.com',
    ccDevBaseURL: 'https://gmdev.warforgalaxy.com',
    devToken: '',
    authTimeout: 10000,
    filterMaxOrder: 4,
    wsUrl: isProd ? 'https://gmbackend.warforgalaxy.com/gamemanager/hub/galaxy' : 'https://gmbackend.warforgalaxy.com/dev/gamemanager/hub/galaxy',
    //wsUrl: 'https://gmbackend.warforgalaxy.com/gamemanager/hub/galaxy',
    sentryDsn: "https://c6ed526c24034c67b120d769925701af@o1010782.ingest.sentry.io/5975456",
    wsSimulator: isProd ? 'wss://srv2.warforgalaxy.com:9980' : 'wss://srv2.warforgalaxy.com:9982'
}