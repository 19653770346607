export default {
    shuttle:"Шаттл",
    fighter:"Истребитель",
    transport: "Транспорт",
    energodrone:"Энергодрон",
    attacker:"Штурмовик",
    frigate:"Фрегат",
    galaction:"Галактион",
    destroyer:"Разрушитель",
    bombardier:"Бомбардир",
    colossus:"Колосс",
    scout:"Разведзонд",
    collector:"Коллектор",
    pioneer:"Первопроходец",
    corvette:"Корвет",
    big_energy_shield:"Большой энергокупол",
    small_energy_shield:"Малый энергокупол",
    lepton_cannon:"Лептонная пушка",
    photon_cannon:"Фотонная пушка",
    graviton_weapon:"Гравитонное орудие",
    ultraviolet_laser:"Ультрафиолет. лазер",
    infrared_laser:"Инфракрасный лазер",
    missile_block:"Ракетный блок",
    titanium_mine:"Титановая шахта",
    silicon_mine:"Кремнивая шахта",
    collider:"Коллайдер",
    annihilation_reactor:"Анигиляционный реактор",
    neutrino_power_station:"Нейтринная электростанция",
    dock:"Док",
    filling_base:"Заправочная база",
    nanofactory:"Нанофабрика",
    nanotechnology_center:"Центр нанотехнологий",
    planetary_energy_center:"Планетарный энергоузел",
    robofactory:"Робофабрика",
    terrestrial_telescope:"Научный центр",
    cosmic_telescope:"Космический телескоп",
    teleport:"Телепорт",
    titanium_silo:"Склад титана",
    silicon_silo:"Склад кремния",
    military_plant: "Центр обороны",
    annihilation_engine: "Аниг. двиг",
    baryonic_engine: "Бар. двиг",
    subspace_engine: "Подпространств. двиг",
    subspace_travel: "Перемещение в подпространстве",
    armament: "Вооружение",
    military_lasers: "Боевые лазеры",
    energetics: "Энергет.",
    energy_shields: "Энерг. поля",
    leptonic_weapon: "Лептонное оружие",
    navigation: "Навигация",
    photonic_weapon: "Фотонное ор.",
    planet_scanning: "Планет. скан",
    planet_exploration: "Освоение планет",
    ship_protection: "Защита кораблей",
    tachyon_scan: "Тахионное сканирование",
    vibrotron: "Вибротрон",
}