import Vue from 'vue'
import App from './App.vue'
import VueColumnsResizable from 'vue-columns-resizable';
import { Plugin } from 'vue-fragment'
import axios from 'axios'
import Notifications from 'vue-notification'
import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'
// import config from './config'

Vue.use(Notifications)

import router from './router'
import storeInit from '@/store'

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueColumnsResizable);
Vue.use(Plugin)

Vue.prototype.$axios = axios;

let debug = document.location.host == "gmdev.warforgalaxy.com" || document.location.href.indexOf("localhost") > -1

if (debug) {
  Vue.prototype.$axios.defaults.baseURL = "https://gmbackend.warforgalaxy.com/dev/gamemanager/";
} else {
  Vue.prototype.$axios.defaults.baseURL = "https://gmbackend.warforgalaxy.com/gamemanager/";
}

const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)

let requestTransaction = null
axios.interceptors.request.use(
  function (config) {
    requestTransaction = Sentry.startTransaction({
      op: 'http',
      name: config.url
    })

    return config
  },
  function (error) {
    requestTransaction = null
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  function (response) {
    if (requestTransaction) {
      requestTransaction.finish()
    }
    return response
  },
  function (error) {
    
    return Promise.reject(error)
  }
)


const store = storeInit(axios)

// Sentry.init({
//   Vue,
//   environment: debug ? "development" : "production",
//   dsn: config.sentryDsn,
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["gmdev.warforgalaxy.com", "cc.warforgalaxy.com"],
//     })
//   ],
//   tracesSampleRate: 0.2,
//   beforeSend: (event, hint) => {
//     if (debug) {
//       console.error(hint.originalException || hint.syntheticException)
//       return null
//     } else {
//       return event
//     }
//   }
// })

new Vue({
  router,
  axios,
  store,
  render: h => h(App)
}).$mount('#app')