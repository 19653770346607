export const loop = async (fn, ms) => {
    try {
        await fn.call(this)
    } catch (err) {
        console.error(err)
    } finally {
        setTimeout(loop.bind(this, fn, ms), ms)
    }
}

export const toggleClassWithDelay = (el, className, ms = 500) => {
    el.classList.add(className)
    setTimeout(() => {
        el.classList.remove(className)
    }, ms)
}

export const blinkColor = el => {
    const CLASSNAME = 'blink'
    const DELAYMS = 700

    toggleClassWithDelay(el, CLASSNAME, DELAYMS)
}
