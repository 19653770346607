import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/reports',
    name: 'Report',
    component: () => import('../views/Report.vue'),
    meta: { hasSubMenu: false }
  },
  {
    path: '/',
    name: 'Planets',
    component: () => import('../views/Planets.vue'),
    meta: { hasSubMenu: false }
  },
  {
    path: '/cad',
    name: 'Cad',
    component: () => import('../views/Cad.vue'),
    meta: { hasSubMenu: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { hasSubMenu: false }
  },
  {
    path: '/simulator',
    name: 'Simulator',
    component: () => import('../views/Simulator.vue'),
    meta: { hasSubMenu: false }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  try {
    router.app.$store.commit('toggleSendFleet', false)
  } catch (err) {  } // eslint-disable-line

  next()
})

export default router
