import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default (axios = {}) => {
    const store = new Vuex.Store({
        state: {
            licenseInfo: {
                available: false,
            },
            token: null,
            techs: [],
            fleets: {},
            planets: [],
            planetFilterState: true,
            sendFleetState: false,
            selectedPlanet: { path: '' }
        },
        getters: {
            isProVersion(state) {
                return state.licenseInfo && state.licenseInfo.available
            },
            usedFleets(state) {
                return state.fleets.amount
            },
            maxFleets(state) {
                const r = 1

                if (state.techs.length < 1) {
                    return r
                }

                for (let t of state.techs) if (t.name === 'navigation') {
                    return r + t.level
                }

                return r
            },
            getTechByCode(state) {
                return code => {
                    for (let t of state.techs) if (t.name === code) {
                        return t
                    }
                    
                    return {}
                }
            },
            licenseLeftDays(state) {
                return state.licenseInfo.leftdays
            },
            planets(state) {
                return state.planets
            },
            filterState(state) {
                return state.planetFilterState
            },
            selectedPlanetCluster(state) {
                return state.selectedPlanet.path ? state.selectedPlanet.path.split(':')[0] : 0
            },
            selectedPlanetPath(state) {
                return state.selectedPlanet.path
            },
            sendFleetState(state) {
                return state.sendFleetState
            },
            getMaxFleetCount(state) {
                return type => {
                    if (!Array.isArray(state.selectedPlanet.fleet)) {
                        return 0
                    }

                    for (let fleetInfo of state.selectedPlanet.fleet) {
                        if (fleetInfo.name === type) {
                            return fleetInfo.amount
                        }
                    }

                    return 0
                }
            },
            token(state) {
                return state.token
            },
            getSelectedPlanetName(state) {
                if (state.selectedPlanet.name === undefined) {
                    return '';
                }

                return state.selectedPlanet.name;
            }
        },
        mutations: {
            setLicense(state, obj) {
                state.licenseInfo = obj
            },
            setToken(state, token) {
                state.token = token
            },
            setTechs(state, techs) {
                if (!techs) {
                    techs = []
                }
                state.techs = techs
            },
            setFleets(state, fleets) {
                state.fleets = fleets
            },
            setPlanets(state, planets) {
                state.planets = planets
            },
            toggleFilterState(state) {
                state.planetFilterState = !state.planetFilterState
            },
            setSelectedPlanet(state, planetObj) {
                state.selectedPlanet = planetObj
            },
            toggleSendFleet(state, newValue = null) {
                if (newValue !== null) {
                    state.sendFleetState = !!newValue
                } else {
                    state.sendFleetState = !state.sendFleetState
                }

            }
        },
        actions: {
            async loadUsedFleets(context) {
                const { data } = await axios.get(`/user/fleets`)
                context.commit('setFleets', data)
                return data
            }
        }
    })

    return store
}




// export default {
//     licenseInfo: {
//         available: false
//     },
//     token: null,
//     techs: [],
//     fleets: {},
//     isProVersion() {
//         return this.licenseInfo && this.licenseInfo.available
//     },
//     getTechByCode(code) {
//         for (let t of this.techs) if (t.name === code) {
//             return t
//         }
        
//         return null
//     },
//     getUsedFleets() {
//         return this.fleets.amount
//     },
//     getMaxFleets() {
//         return this.getTechByCode('navigation').level
//     }
// }
